$(document).ready(function() {
    document.getElementById("IncreaseInvoicePositions").addEventListener("click",
        function(){
            event.preventDefault();
            var table = document.getElementById("PositionTable");
            var row = table.insertRow();
            amountPositions = amountPositions + 1;
            var cell = row.insertCell();
            cell.innerHTML = "<div class='center-text'>"+amountPositions+"</div>";
            cell = row.insertCell();
            cell.innerHTML = "<input required=\"required\" label=\"false\" type=\"text\" class=\"form-control\" name=\"out_invoice[invoice_positions_attributes]["+(amountPositions-1)+"][description]\" id=\"out_invoice_invoice_positions_attributes_"+(amountPositions-1)+"_description\">";
            cell = row.insertCell();
            cell.innerHTML = "<div class=\"input-group-append\"><input required=\"required\" label=\"false\" type=\"text\" class=\"form-control text-right\" name=\"out_invoice[invoice_positions_attributes]["+(amountPositions-1)+"][net]\" id=\"out_invoice_invoice_positions_attributes_"+(amountPositions-1)+"_net\"><span class=\"input-group-text\" style=\"width: 40px;\">€</span></div>";
            cell = row.insertCell();
            cell.innerHTML = "<div class=\"input-group-append\"><input required=\"required\" label=\"false\" type=\"text\" class=\"form-control col-xs-1\" name=\"out_invoice[invoice_positions_attributes]["+(amountPositions-1)+"][vat_rate]\" id=\"out_invoice_invoice_positions_attributes_"+(amountPositions-1)+"_vat_rate\"><span class=\"input-group-text\" style=\"width: 40px;\">%</span></div>";
            console.log("Invoice positions increased");
        }
    );
});